var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c("gov-heading", { attrs: { size: "l" } }, [
                _vm._v("Taxonomy: Service Eligibilities")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        This page shows the list of service eligibility options a user can\n        select from when populating information about who is eligible to\n        access their service.\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        From this page, you can edit, delete, and add to the options that\n        appear on the list.\n      "
                )
              ])
            ],
            1
          ),
          _vm.auth.isSuperAdmin
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "taxonomies-service-eligibilities-create" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new service eligibility")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _vm._l(_vm.serviceEligibilities, function(rootTaxonomy) {
        return _c(
          "gov-grid-row",
          { key: rootTaxonomy.id },
          [
            _c(
              "gov-grid-column",
              { attrs: { width: "two-thirds" } },
              [
                _c("gov-heading", { attrs: { size: "m" } }, [
                  _vm._v(_vm._s(rootTaxonomy.name))
                ]),
                _c("ck-taxonomy-list", {
                  attrs: {
                    taxonomies: rootTaxonomy.children,
                    filteredTaxonomyIds: true,
                    edit: "taxonomies-service-eligibilities-edit",
                    bullet: true
                  },
                  on: { "move-up": _vm.onMoveUp, "move-down": _vm.onMoveDown }
                })
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }